import { Link, usePage } from '@inertiajs/react';
import { Box, Button, Container, Group, Text, Title } from '@mantine/core';

import classes from './Hero.module.css';

export default function Hero() {
  const user = usePage().props?.auth?.user;

  return (
    <Container>
      <div className={classes.inner}>
        <div className={classes.content}>
          <Title className={classes.title}>Fractional Islamic Art Investing Made Possible</Title>
          <Text mt="md" className={classes.subtitle}>
            Co-ownership of exquisite Islamic artworks grants you a stake in cultural heritage and financial growth.
          </Text>

          <Group mt={30}>
            <Button component={Link} href={user ? '/art-sales' : '/register'} size="md" variant="outline-gf">
              BUY NOW
            </Button>
          </Group>
        </div>
        <Box maw={{ base: 350 }} m={'0 auto'}>
          <video className={classes.video} autoPlay muted loop playsInline preload="auto" onContextMenu={() => false}>
            <source src="/img/gf-home-opt.mp4" type="video/mp4" />
          </video>
        </Box>
      </div>
    </Container>
  );
}
